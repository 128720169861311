import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { IamWrapperService } from '../services/iam-wrapper.service';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';
import { catchError, finalize, of, switchMap, tap } from 'rxjs';
import { UnauthorizeService } from '../services/unauthorize.service';
import { ConfigurationFunctionService } from '../services/configurationapi/configurationfunctionapi.services';
import { CommonConstants } from 'src/app/shared/constants/common-constants';
import { ToastrService } from 'ngx-toastr';

export const CompanyContextResolver: ResolveFn<any> = () => getResolverData();

const getResolverData = (
  iamWrapperService = inject(IamWrapperService),
  commonFunctions = inject(CommonFunctions),
  unauthorizeService = inject(UnauthorizeService),
  configApiService = inject(ConfigurationFunctionService),
  toastr = inject(ToastrService)
) => {
  if (unauthorizeService.isUnauthorized) {
    return of([]);
  }

  commonFunctions.showLoading();
  return configApiService.getFeaturesByApplicationAndAllCompanies(CommonConstants.U2ApplicationName).pipe(
    tap(companyAgnosticConfigs => {
      if (companyAgnosticConfigs !== null && companyAgnosticConfigs !== undefined) {
        sessionStorage.setItem(CommonConstants.CompanyAgnosticFeaturesStorageKey, btoa(JSON.stringify(companyAgnosticConfigs.features)));
      }
    }),
    switchMap(() => iamWrapperService.getUserAccessedCompanies().pipe(
      tap(userAccessedCompanies => {
        if (userAccessedCompanies) {
          iamWrapperService.UserAccessedCompany = userAccessedCompanies;
          const existingCompanyCode = sessionStorage.getItem('CompanyCode');
          if (!existingCompanyCode) {
            const [company] = userAccessedCompanies;
            if (company) {
              const companyName = company.companyName;
              sessionStorage.setItem('CompanyCode', companyName);
            }
          }
        }
      })
    )),
    finalize(() => commonFunctions.hideLoading()),
    catchError(error => {
      commonFunctions.handleErrorResponse(error, toastr, CommonConstants.UnableToLoadFeatures);
      commonFunctions.hideLoading();
      return of([]);
    })
  );
};
